<template>
	<div class="pageContainer targetPage flexCloumn">
		<el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
			<div class="flexStart flex_1">
				<tchtree v-model="searchForm.class_ids"></tchtree>
				<el-input placeholder="请输入关键字" v-model="searchForm.keyword" size="small" class="input-with-select"
					style="width:300px" clearable>
					<el-select v-model="searchForm.ktype" slot="prepend" placeholder="请选择" style="width:120px">
						<el-option label="学生姓名" value="stu_name"></el-option>

					</el-select>
				</el-input>

				<el-select v-model="searchForm.sp_status" clearable placeholder="请选择" size="small"
					style="width:130px;margin-left:10px">
					<el-option label="全部" value=""></el-option>
					<el-option label="草稿" :value="0"></el-option>
					<el-option label="待审核" :value="1"></el-option>
					<el-option label="审核通过" :value="2"></el-option>
					<el-option label="审核拒绝" :value="3"></el-option>
				</el-select>
				<el-button icon="el-icon-search" style="margin-left:10px" size="small" type="primary"
					@click="() => { page.current_page = 1; getList() }">
					搜索
				</el-button>
			</div>

			<div class="flexStart"
				style="color: #3378fa;font-size: 14px;font-weight: 700;margin: 0 5px 0 30px;cursor: pointer;">

				<!-- <el-button icon="el-icon-download" style="margin-left:10px" size="small" @click="exportXls">
					导出
				</el-button> -->

			</div>
		</el-row>
		<div class="dividerBar"></div>

		<el-table :data="DataList" height="100%" size="small" stripe border
			style="width:fit-content;width:100%;">
			<el-table-column type="index" width="65" label="序号" align="center" />

			<el-table-column prop="stu_name" label="学生姓名" width="100" align="center" />
			<el-table-column prop="class_name" label="班级" show-overflow-tooltip width="180" align="center" />
			<el-table-column prop="cdate" label="提交时间" show-overflow-tooltip width="200" align="center" />


			<el-table-column prop="content_txt" label="就业指导课程内容摘要" show-overflow-tooltip min-width="100" align="center" />



			<el-table-column prop="sp_status" label="审批状态" align="center" width="100">
				<template v-slot="scope">
					<el-tag v-if="scope.row.sp_status == 0" size="small">草稿中</el-tag>
					<el-tag v-if="scope.row.sp_status == 1" size="small">待审核</el-tag>
					<el-tag v-if="scope.row.sp_status == 2" type="success" size="small">审核通过</el-tag>
					<el-tag v-if="scope.row.sp_status == 3" type="danger" size="small">审核拒绝</el-tag>
				</template>
			</el-table-column>

			<el-table-column prop="sp_username" label="审批人" width="120" align="center" />



			<el-table-column label="操作" width="90" align="center">
				<template slot-scope="scope">

					<el-tag class="pointer" type="success" size="mini" @click="viewInfo(scope.row)"
						v-if="scope.row.sp_status == 1">
						审批</el-tag>

					<el-tag class="pointer" type="primary" size="mini" @click="viewInfo(scope.row)" v-else>详情</el-tag>

				</template>
			</el-table-column>
		</el-table>

		<el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
			:page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
			:page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
		</el-pagination>



		<el-dialog class="cus_dialog" ref="ruleForm" title="就业指导详细" :visible.sync="dialogshow" :close-on-click-modal="false"
			width="60%">
			<el-form ref="form" :model="EditItem" label-width="80px">
				<el-row>
					<el-col :span="11">
						<el-form-item label="姓名">
							<el-input v-model="EditItem.stu_name" readonly></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="11">

						<el-form-item label="班级">
							<el-input v-model="EditItem.class_name" readonly></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="11">
						<el-form-item label="提交时间">
							<el-input v-model="EditItem.cdate" readonly></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="11">

						<el-form-item label="状态">
							<el-tag v-if="EditItem.sp_status == 0" size="small">草稿中</el-tag>
							<el-tag v-if="EditItem.sp_status == 1" size="small">待审核</el-tag>
							<el-tag v-if="EditItem.sp_status == 2" type="success" size="small">审核通过</el-tag>
							<el-tag v-if="EditItem.sp_status == 3" type="danger" size="small">审核拒绝</el-tag>
							<el-tag v-if="EditItem.sp_status == 3" type="danger" size="small">{{ EditItem.sp_word }}</el-tag>
						</el-form-item>
					</el-col>
				</el-row>

				<el-row>
					<el-col :span="22">
						<el-form-item label="就业指导课程内容摘要">
							<el-input type="textarea" autosize v-model="EditItem.content_txt"></el-input>
						</el-form-item>
					</el-col>

				</el-row>






			</el-form>

			<div v-if="EditItem.sp_status == 1" style="padding-bottom: 120px; padding-top: 100px;">

				<el-col :span="14" style="">
					<el-input v-model.trim="EditItem.sp_word" size="small" placeholder="审核拒绝时请填写拒绝原因"></el-input>
				</el-col>
				<el-col :span="10" style="padding-left: 80px;">
					<el-button size="small" icon="el-icon-back" type="warning" @click="submitForm(3)">审核拒绝</el-button>
					<el-button size="small" icon="el-icon-check" type="primary" @click="submitForm(2)">审核通过</el-button>
				</el-col>
			</div>

		</el-dialog>






	</div>
</template>

<script>
import tchtree from "../com/tchTree.vue";
export default {
	components: {
		tchtree
	},
	data() {
		return {
			temphead: "//school.mmteck.cn/static/temp.png",

			dialogshow: false,
			searchForm: {
				class_ids: "",
				sp_status: "",
				ktype: "stu_name",
				keyword: "",
				month: ""

			},
			EditItem: {},
			DataList: [],

			page: {
				count: 0,
				current_page: 1,
				per_page: 50,
				total_page: 0
			}


		};
	},
	mounted() {
		let year = new Date().getFullYear()
		let month = new Date().getMonth() + 1
		this.searchForm.month = year + '-' + month
		this.getList()
	},
	methods: {
		getList() {
			this.$http.post("/api/jyzd_list", {
				page: this.page.current_page,
				pagesize: this.page.per_page,
				ktype: this.searchForm.ktype,
				keyword: this.searchForm.keyword,
				sp_status: this.searchForm.sp_status,
				class_ids: this.searchForm.class_ids

			}).then(res => {
				this.DataList = res.data.data
				this.page = res.data.page
			})
		},
		handlePageChange(page) {
			this.page.current_page = page
			this.getList()
		},
		handleSizeChange(val) {
			this.page.current_page = 1;
			this.page.per_page = val;
			this.getList()
		},
		addNew() {
			this.EditItem.id = 0;
			this.dialogshow = true;
		},
		viewInfo(row) {
			this.EditItem = row
			this.dialogshow = true;
		},
		exportXls() {
			this.$http.post("/api/stu_leave_list_export", {
				page: this.page.current_page,
				pagesize: 2000,
				ktype: this.searchForm.ktype,
				keyword: this.searchForm.keyword,
				sp_status: this.searchForm.sp_status,
				month: this.searchForm.month,

			}).then(res => {
				if (res.data.url) {
					window.open(res.data.url)
				}
			})
		},
		submitForm(st) {
			this.$confirm('确定审核?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.EditItem.sp_status = st
				this.$http.post("/api/jyzd_sp", this.EditItem).then(res => {
					this.getList()
					this.$message({
						type: "success",
						message: "审核成功!"
					});
					this.dialogshow = false
				})

			}).catch(() => {

			});
		}
	},
}
</script>

<style scoped="scoped">
.spuser {
	display: inline-block;
	border: 1px solid rgb(255, 148, 62);


	padding: 2px;
	margin-right: 10px;
	margin-bottom: 5px;
	border-radius: 4px;
	padding: 4px 10px;
}

.spuser img {
	width: 20px;
	height: 20px;
	display: inline-block;
	vertical-align: top;
	border-radius: 50%;
}
</style>
